









































import Vue from 'vue';
import { mapMutations } from 'vuex';
import { Trash2Icon } from 'vue-feather-icons';
import { EnumsActions, EnumsIcons } from '@/store/modules/globals/types';
import IBoxesRepository from '@/services/Repositories/Boxes/types';
import RepositoryFactory from '@/services/RepositoryFactory';

const factory = new RepositoryFactory();

export default Vue.extend({
  name: 'ReboxNote',
  data() {
    return {
      note: '',
      popup: false,
      boxStatus: '',
    };
  },
  props: ['items'],
  components: {
    BasePopup: () => import('@/components/ui/BasePopup.vue'),
    Trash2Icon,
  },
  computed: {
    reboxId(): string {
      return this.$route.params.reboxId;
    },
    boxesRepository(): IBoxesRepository {
      return factory.get('boxes') as IBoxesRepository;
    },
  },
  methods: {
    ...mapMutations('globals', ['SET_TITLE', 'SET_ACTION', 'SET_ICON']),
    ...mapMutations('lastEdited', ['ADD_EDITED_BOX']),
    async returnBox() {
      // eslint-disable-next-line max-len
      const { data } = await (this as any).boxesRepository.returnDamagedBox(this.$route.params.reboxId, {
        boxReturn: {
          notes: this.note,
          boxReturnInventoryItems: (this as any).items,
        },
      });
      this.boxStatus = data.status;
    },
    openPopup(): void {
      this.returnBox();
      this.popup = true;
    },
    backToScanProccess(): void {
      this.ADD_EDITED_BOX(this.reboxId);
      this.$router.replace({ name: 'ReboxScanner' });
      this.popup = false;
    },
    goBack(): void {
      this.$router.go(-1);
    },
  },
  created() {
    this.SET_TITLE('header.reboxCheck');
    this.SET_ACTION(EnumsActions.goBack);
    this.SET_ICON(EnumsIcons.back);
  },
  watch: {
    note: {
      handler(val: string) {
        if (val.length) {
          this.SET_ACTION(EnumsActions.close);
          this.SET_ICON(EnumsIcons.cancel);
        } else {
          this.SET_ACTION(EnumsActions.goBack);
          this.SET_ICON(EnumsIcons.back);
        }
      },
    },
  },
});
